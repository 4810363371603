<template>
  <static-fullscreen-card>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <template v-slot:title> ({{ data.id }}) {{ m.title + ` №${data.code_doc}` }} [{{ statusCurrent.text || "" }}] </template>
    <template v-slot:actions>
      <a-btn-send-code v-if="id && [1, 3].includes(data.status)" :id="id" :api="'/accounting/doc/act_work_client'" @success="fitchData(id)" :title="'Подписать(SMS)'" />
      <a-btn-status
        v-if="getAccess('documents.actWorkClient.status', { users: [data.createdby_id] })"
        :items="statusItems"
        @click="changeStatus($event)"
        :disabled="canStatus"
      />
      <a-btn-edit v-if="data.status === 0 && accessEdit" @click="showEditDialog = true" :disabled="!canEdit" />
      <a-btn-delete
        v-if="id && data.status === 0 && getAccess('documents.actWorkClient.delete', { users: [data.createdby_id] })"
        :disabled="!canDelete"
        @click="removeDialogShow = true"
      />
    </template>
    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true, hideNull: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true }" :style1="{ height: blockHeight + 'px' }" @click="getBalance()" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2 pt-1">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3" :href="`#tab-${tab.name}`">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <v-card-text v-if="t.name == 'detail'" :style="styleDetailCard" class="pa-0">
                <a-table-f-data2
                  ref="tableDetail"
                  :dataTable="data.data_table"
                  :useQuery="false"
                  :model="modelDetail"
                  :searchable="false"
                  :defaults="{
                    filters: {},
                    sort: { key: 'id', order: 'DESC' },
                    paramName: 'documentActWorkClient',
                  }"
                  @click="onClickRow($event, 'detail')"
                >
                  <template v-slot:item.actions="{ row }">
                    <div class="d-flex" v-if="data.status === 0 && accessEdit">
                      <v-btn x-small fab class="ma-0" color="red" title="Удалить" @click.stop="delRow(row.id)">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template v-slot:top>
                    <v-fab-transition v-if="data.status === 0 && accessEdit">
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="addRecord">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-data2>
              </v-card-text>
              <v-card-text v-if="t.name == 'files'" :style="styleDetailCard" class="pa-0">
                <v-fab-transition v-if="true">
                  <v-btn fab dark color="green" absolute bottom small style="bottom: 25px" left class="v-btn--example" @click="addNewDocs('doc_all')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
                <a-view-filesdata :value="data.filesData" />
              </v-card-text>
              <a-view-images v-if="t.name == 'photos'" :value="data.photos" :style="styleDetailCard" />
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <EditDetail
        v-if="showDialogEditDetail"
        v-model="showDialogEditDetail"
        :id="idEdit"
        :dataTable="data.data_table"
        :readonly="data.status !== 0"
        :RO="fieldsRO"
        :parent_data="data"
        @refresh="fitchData()"
        @save="editRows($event)"
      />
      <s-document-head v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="m.title" :id="id" />
      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
      <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
    </portal>
    <confirm-dialog ref="confirmDialog" />
    <change-value-dialog ref="changeValueDialog" />
    <a-loader v-if="loading" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
    <s-editor-editor
      v-if="getAccess('suData')"
      ref="suEdit"
      v-model="suEditShow"
      :config="{ table: 'accounting_doc_act_work_client', filter: { id } }"
      @show="suEditShow = true"
      :activate="(id || 0) > 0"
    />
  </static-fullscreen-card>
</template>

<script>
import { autoHeightBlock, getAccess, popupMenu, removeEl, genModel } from "@/components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, genModel],
  props: {
    idShow: Number,
  },
  components: {
    EditDetail: () => import("./../dialogs/actWorkClientDetailDialog"),
    //   editDialog: () => import("./../dialogs/objectSellDialog"),
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      idDeleting: 0,
      fieldsRO: [],
      suEditShow: false,
      showEditDialog: false,
      showDialogEditDetail: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      showAddDocDialog: false,
      addDocModel: null,
      loading: false,
      loaded: false,
      title: "",
      data: {},
      configHead: null,
      m: this.$store.getters["config/get"].models.documentActWorkClient,
      modelDetail: this.$store.getters["config/get"].models.documentActWorkClient.listDetail,
      tab: 0,
      tabs: [
        { name: "detail", title: "Детали", show: true },
        { name: "files", title: "Документы", show: true },
        { name: "photos", title: "Галерея", show: true },
      ],

      statusCur: null,
    };
  },
  created() {
    this.loading = true;

    this.$root.title = this.m.title;
    this.id = this.idShow || Number(this.$route.params.id);
    //    this.permit = this.getAccess("menu.objects");
    this.fitchData();
    this.loaded = true;
  },
  mounted() {},

  computed: {
    accDocName() {
      return this.m.accDocName;
    },
    api() {
      return this.m.api;
    },
    url() {
      return this.m.api;
    },
    statuses() {
      return this.m.statuses;
    },
    accDocName() {
      return this.m.accDocName;
    },
    accessEdit() {
      return this.getAccess("documents.actWorkClient.edit", { users: [this.data.createdby_id] });
    },
    balance() {
      return { debit: this.debit, credit: this.credit };
    },
    canStatus() {
      return false;
    },
    canEdit() {
      if (this.data.data_table && this.data.data_table.length) return false;
      return true;
    },
    canDelete() {
      if (this.data.data_table && this.data.data_table.length) return false;
      return true;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1");
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    model: {
      get() {
        let type = this.data.operation_type || "default";
        let config = JSON.parse(JSON.stringify(this.m?.config?.[type] || this.m?.config.default || {}));
        this.configHead = config;
        let model = this.calcModelConfig(config);
        return model;
      },
    },
    statusItems() {
      if (!this.data.status && this.data.status !== 0) return [];
      let items = [];
      let res = [];
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next) {
        let arr = statusCur.next;
        res = this.statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      res = res.filter((s) => this.getAccess("documents.actWorkClient.status." + s.value, null, true));
      res.forEach((s) => {
        if (!this.getAccess("documents.actWorkClient.status." + s.value, null, true)) s.disabled = true;
      });
      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let statusCur = this.statuses.find((el) => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
    showDialogEditDetail(v) {
      if (!v) {
        this.fitchData();
      }
    },
    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
  },
  methods: {
    addNewDocs(field) {
      this.addDocModel = this.model.find((el) => el.name == field) || null;
      if (!this.addDocModel) return;
      this.showAddDocDialog = true;
    },
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.loading = false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$router.push({ name: "actWorkClient" });
    },
    afterFetchData(r) {
      if (this.id) {
        //   this.calcModel();
      }
      this.loaded = true;
      // this.initType = null;

      //  this.calcModel(1);
    },

    async changeStatus(status) {
      let ok = false;
      /*   ok = await this.$refs.confirmDialog.show({
        title: "Изменение статуса",
        message: [`Вы действительно хотите ${status.value !== 2 ? "распровести" : "провести"} заказ?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
*/

      this.loading = true;
      let data = { id: this.id, status: status.value };
      let response = await this.$axios.post(this.api, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
    },
    addRecord() {
      if (this.data.operation_type == 60 || true) {
        this.idEdit = 0;
        this.showDialogEditDetail = true;
        return;
      }
    },
    async editRows(r) {
      console.log(r);
      const api = this.url;
      const data_table = [...r];
      const data = { id: this.id, data_table };
      let response = await this.$axios.post(api, data);
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные сохранены",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка добавления данных",
        });
      }
      this.fitchData();
    },
    async delRow(id) {
      if (this.idDeleting) return;
      this.idDeleting = id;
      const api = this.url;
      const data_table = this.data.data_table.filter((el) => el.id !== id);
      const data = { id: this.id, data_table };
      //const data = { id: this.id, data_service };
      try {
        let response = await this.$axios.post(api, data);
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Данные удалены",
          });
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка удаления ",
          });
        }
      } catch (error) {}
      this.idDeleting = 0;
      this.fitchData();
    },
    onClickRow(e, type = "") {
      if (e.field?.isMenu) {
        let name = e.field?.alias || e.field.name;
        let id = e.row?.[e.field.name];
        if (id) this.showPopupMenu(name, e.row, e.event, { task: { id: e.row[e.field.name] } });
      } else if (e.field.name == "actions") {
      } else {
        if (type == "detail") {
          this.idEdit = e.row.id;
          this.showDialogEditDetail = true;
          return;
        }
      }
    },
  },
};
</script>
